import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VCardTitle,{staticClass:"pt-0 pb-0"},[_c('span',{staticClass:"title-page"},[_vm._v("Desligamento de Funcionário")]),_c(VSpacer),_c(VTextField,{staticClass:"mt-6",attrs:{"label":"Buscar por tipo de desligamento, loja, requisitante e status","outlined":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.findFilterResult()}},model:{value:(_vm.filterData),callback:function ($$v) {_vm.filterData=$$v},expression:"filterData"}}),_c(VBtn,{staticClass:"ml-4",attrs:{"color":"info","outlined":""},on:{"click":function($event){return _vm.toggleModal('create', true)}}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlaylistPlus)+" ")]),_vm._v(" NOVA REQUISIÇÃO ")],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.listOfFilteredItems,"loading":_vm.isLoadingData,"no-data-text":"Nenhum registro para exibir","loading-text":"Carregando dados..."},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"me-2",attrs:{"medium":"","color":"warning"},on:{"click":function($event){return _vm.handleModal(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiEye)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VAlert,{staticClass:"badge",attrs:{"color":_vm.getStatusColor(item),"text":""}},[_vm._v(" "+_vm._s(item.status ? item.status : '')+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.created_at))+" ")]}}])})],1),_c(VDialog,{attrs:{"width":"600px"},model:{value:(_vm.showModalCreate),callback:function ($$v) {_vm.showModalCreate=$$v},expression:"showModalCreate"}},[_c('modal-create',{on:{"closeModalCreate":function($event){return _vm.toggleModal('create', false)},"updatedTable":function($event){return _vm.updatedTable()}}})],1),_c(VDialog,{attrs:{"width":"600px"},model:{value:(_vm.showModalDetails),callback:function ($$v) {_vm.showModalDetails=$$v},expression:"showModalDetails"}},[_c('modal-details',{key:_vm.modalData.id,attrs:{"data":_vm.modalData,"user-profile":_vm.getUserProfile()},on:{"closeModalDetails":function($event){return _vm.toggleModal('details', false)},"updatedTable":function($event){return _vm.updatedTable()}}})],1),_c(VDialog,{attrs:{"width":"700px"},model:{value:(_vm.showModalRD),callback:function ($$v) {_vm.showModalRD=$$v},expression:"showModalRD"}},[_c('ModalRD',{key:_vm.modalData.id,attrs:{"data":_vm.modalData,"user-profile":_vm.getUserProfile()},on:{"closeModalRD":function($event){return _vm.toggleModal('modalRD', false)},"updatedTable":function($event){_vm.updatedTable(), _vm.toggleModal('modalRD', false)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }